<template>
  <div class="form-group" :class="{'has-error': field.$error}">
    <label class="signin-label" :class="{'text-danger': field.$dirty&&field.$invalid}">{{ label || name }}</label>
    <slot></slot>
    <p class="help-block text-danger" v-if="field.$dirty&&!field.required">{{ $t('form-group.required') }}</p>
    <p class="help-block text-danger" v-if="field.$dirty&&!field.minLength&&field.$params.minLength">
      {{ $t('form-group.min-length-error', {length: field.$params.minLength.min}) }}</p>
  </div>
</template>
<script>
export default {
  name: 'FormGroup',
  props: {
    field: {
      type: Object,
      default() {
        return {}
      }
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  }
}
</script>
<style></style>